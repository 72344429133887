import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <i>Welcome You All, Contents are coming soon.................</i>
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>Write Less and Do More</b>
        </a>
        <br/>
        <br/>    
        <a
          className="App-link"
          href="https://res.cloudinary.com/practicaldev/image/fetch/s--YTBmrW5N--/c_imagga_scale,f_auto,fl_progressive,h_900,q_auto,w_1600/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/jvolqdc8lw4r9i3yrdwp.jpeg
"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i><small style={{ color: 'red' }}>The Best is Yet to Come</small></i>
        </a>
      </header>
    </div>
  );
}

export default App;
